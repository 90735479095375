import React, { useState } from 'react';
import Box from '../../../../../remitbee/components/box/Box';
import classes from './HeroSection.module.scss';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../../remitbee/theme/Theme';
import Typography, { Variant } from '../../../../../remitbee/components/typography/Typography';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Button from '../../../../../remitbee/components/button/Button';
import Placeholder from '../../../../../remitbee/icons/PlaceholderIcon';
import { setRedirectSignupCookie } from '../../../../../shared/cookie-handler';
import { useRouter } from 'next/router';
import CheckIconCircled from '../../../../../remitbee/icons/CheckIconCircled';
import Markdown from 'markdown-to-jsx';
import MultiConverter from '../../../components/DesignSystem/MultiConverterTab/MultiConverter';
import { getImageAlt, parseArrFromString } from '../../../../../shared/utility';
import Image from '../../../../../remitbee/components/image/Image';
import SocialProof from './SocialProof';
import NextImage from 'next/image';
import Avatars from '../../../../../../public/design-system/landing/Avatars.svg'
import StarIcon from '../../../../../../public/design-system/landing/UserReviewsSection/Star.svg';
import HelpSearch from './HelpSearch';

interface Props {
    reverse?: boolean;
    reverseMobile?: boolean;
    title: string | React.ReactNode;
    titleVariant?: Variant;
    titleWeight?: string;
    titleColor?: string;
    titleAlign?: string;
    tag?: string;
    tagVariant?: string;
    tagWeight?: string;
    tagColor?: string;
    tagAlign?: string;
    text?: string | React.ReactNode;
    textVariant?: string;
    textWeight?: string;
    textColor?: string;
    textAlign?: string;
    primaryButtonText?: string;
    primaryButtonAction?: string;
    primaryButtonVariant?: string;
    primaryButtonStartIconVariant?: string | boolean;
    primaryButtonEndIconVariant?: string;
    secondaryButtonText?: string;
    secondaryButtonAction?: string;
    secondaryButtonVariant?: string;
    secondaryButtonStartIconVariant?: string;
    secondaryButtonEndIconVariant?: string;
    listItems?: string;
    imageUrl?: string;
    contentType?: string;
    bottomListItems?: string;
    bottomListItemsAlign?: string;
    mainListItems?: string;
    mainListItemsGrid?: number | string;
    backgroundColor?: string;
    promotion?: any;
    isMobileServer?: boolean;
    imageWidth?: number;
    imageHeight?: number;
    socialProof?: any;
    moneyTransferProps?: any;
    type?: any;
    userReviews?: any;
    customImage?: any;
    storybookMock?: any;
    showHelpSearch?: boolean;
}

const HeroSection: React.FC<Props> = ({
    reverse,
    reverseMobile,
    title,
    titleVariant,
    titleWeight,
    titleColor,
    titleAlign,
    tag,
    tagVariant,
    tagWeight,
    tagColor,
    tagAlign,
    text,
    textVariant,
    textWeight,
    textColor,
    textAlign,
    primaryButtonText,
    primaryButtonAction,
    primaryButtonVariant,
    primaryButtonStartIconVariant,
    primaryButtonEndIconVariant,
    secondaryButtonText,
    secondaryButtonAction,
    secondaryButtonVariant,
    secondaryButtonStartIconVariant,
    secondaryButtonEndIconVariant,
    listItems,
    imageUrl,
    contentType,
    bottomListItems,
    mainListItems,
    mainListItemsGrid,
    backgroundColor,
    promotion,
    bottomListItemsAlign = false,
    imageWidth,
    imageHeight,
    isMobileServer,
    socialProof,
    moneyTransferProps,
    type,
    userReviews,
    customImage,
    showHelpSearch,
    storybookMock
}) => {
    const isMobile = isMobileServer || useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
    const list = listItems ? typeof listItems === 'string' ? parseArrFromString(listItems) : listItems : null;
    const bottomList = bottomListItems ? typeof bottomListItems === 'string' ? parseArrFromString(bottomListItems) : bottomListItems : null;
    const mainList = mainListItems ? typeof mainListItems === 'string' ? parseArrFromString(mainListItems) : mainListItems : null;
    const mainListGrid = mainListItemsGrid || mainList?.length
    const router = useRouter();
    const MTProps = typeof moneyTransferProps === 'string' ? parseArrFromString(moneyTransferProps) : moneyTransferProps;
    const [searchText, setSearchText] = useState('');

    const addReferralLink = () => {
        let _args = {}
        _args = { promo: promotion };
        return _args;
    }

    const goToUrl = async (actionUrl) => {
        if (!actionUrl) return;
        if (actionUrl.startsWith('http')) {
            window.open(actionUrl, '_blank');
        } else if (actionUrl.startsWith('www')) {
            window.open(`https://${actionUrl}`, '_blank');
        } else {
            let query = null;
            if (actionUrl && actionUrl.includes('signup')) await setRedirectSignupCookie(router.asPath)
            if (actionUrl && actionUrl.includes('signup?')) {
                router.push(actionUrl);
                return
            }
            if (promotion) query = addReferralLink()
            router.push({ pathname: actionUrl, query });
        }
    };

    const getComponents = () => {
        switch (contentType) {
            case 'MultiCurrencyConverter': return <MultiConverter storybookMock={storybookMock} />
            case 'SendMoneyConverter':
                return (
                    <Box className={classes['rb-se-box']} style={{ backgroundColor: backgroundColor || null }}>
                        <MultiConverter storybookMock={storybookMock} componentType="moneyTransfer" moneyTransferProps={MTProps[0]} />
                    </Box>
                );
            case 'CurrencyExchange':
                return (
                    <Box className={classes['rb-ce-box']} style={{ backgroundColor: backgroundColor || null }}>
                        <MultiConverter storybookMock={storybookMock} componentType="currencyExchange" moneyTransferProps={MTProps[0]} />
                    </Box>
                );
            case 'HorizontalExchangeConverter':
                return (
                    <Box className={classes['rb-he-box']} style={{ backgroundColor: backgroundColor || null }}>
                        <MultiConverter storybookMock={storybookMock} componentType="currencyExchange" type="horizontal" />
                    </Box>
                );
        }
    }

    const redirectToAction = (action) => {
        if (action) {
            if (action.startsWith('http')) {
                window.open(action, '_blank');
            } else if (action.startsWith('www')) {
                window.open(`https://${action}`, '_blank');
            } else {
                router.push(action);
            }
        }
    };

    const primaryButton = (primaryButtonText && <Button
        fullWidth={isMobile}
        id={'columnImagePrimaryBtnId'}
        className={classes['rb-button-primary']}
        variant={primaryButtonVariant ? primaryButtonVariant : 'filled'}
        onClick={() => goToUrl(primaryButtonAction)}
        startIcon={primaryButtonStartIconVariant ? <Placeholder color={primaryButtonStartIconVariant === 'string' ? primaryButtonStartIconVariant : theme.palette.background.white} /> : null}
        endIcon={primaryButtonEndIconVariant ? <Placeholder color={primaryButtonEndIconVariant === 'string' ? primaryButtonEndIconVariant : theme.palette.background.white} /> : null}
    >
        {primaryButtonText}
    </Button>)

    const secondaryButton = secondaryButtonText && (<Button
        fullWidth={isMobile}
        id={'columnImageSecondaryBtnId'}
        className={classes['rb-button-secondary']}
        variant={secondaryButtonVariant ? secondaryButtonVariant : 'outlined'}
        onClick={() => goToUrl(secondaryButtonAction)}
        startIcon={secondaryButtonStartIconVariant ? <Placeholder color={secondaryButtonStartIconVariant === 'string' ? secondaryButtonStartIconVariant : theme.palette.primary.blue} /> : null}
        endIcon={secondaryButtonEndIconVariant ? <Placeholder color={secondaryButtonEndIconVariant === 'string' ? secondaryButtonEndIconVariant : theme.palette.primary.blue} /> : null}
    >
        {secondaryButtonText}
    </Button>)

    return (
        <section className={`${classes['rb-type-blue']}`} style={{ backgroundColor: backgroundColor || null }}>
            <Box className={classes['rb-root']}>
                <Box
                    className={`${classes['rb-main-grid']} ${type === 'centered' && classes['rb-main-centered']}`}
                    display='flex'
                    flexDirection={(isMobile || type === 'centered') ? reverseMobile ? 'column-reverse' : 'column' : reverse ? 'row-reverse' : 'row'}
                    gap={isMobile ? 48 : 64}
                >
                    <Box className={`${classes['rb-info-grid']} ${!(imageUrl || contentType) && classes['rb-info-full-grid']} `}>
                        <Box display='flex' flexDirection='column' gap={8}>
                            {tag && <Typography
                                className={classes['rb-tag']}
                                variant={tagVariant || 'textLg'}
                                weight={tagWeight || 'bold'}
                                color={tagColor || theme.palette.primary.blue}
                                align={tagAlign || (isMobile ? 'center' : 'left')}
                            >
                                {typeof tag === 'string' ? <Markdown children={tag} /> : tag}
                            </Typography>}
                            {title && <Typography
                                className={classes['rb-title']}
                                variant={(titleVariant || 'display2Xl')}
                                weight={titleWeight || 'bold'}
                                color={titleColor || theme.palette.primary.navy}
                                align={titleAlign || (isMobile ? 'center' : 'left')}
                            >
                                {typeof title === 'string' ? <Markdown children={title} /> : title}
                            </Typography>}
                        </Box>
                        <Spacing variant='betweenHeaderToSubheader' />
                        {text &&
                            <Typography
                                paragraph={true}
                                variant={textVariant || 'textMd'}
                                weight={textWeight || 'regular'}
                                className={classes['rb-text']}
                                color={textColor || theme.palette.input.body}
                                align={textAlign || (isMobile ? 'center' : 'left')}
                            >
                                {typeof text === 'string' ? <Markdown children={text} /> : text}
                            </Typography>}
                        {socialProof && !isMobile && <SocialProof />}
                        {(primaryButton || secondaryButton) && <Spacing variant='betweenSections' />}
                        {(primaryButton || secondaryButton) && <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={12}>
                            {!bottomListItemsAlign && primaryButton}
                            {
                                bottomListItemsAlign && <Box display={bottomListItemsAlign && (!isMobile ? 'flex' : '')}>
                                    {bottomListItemsAlign && primaryButton}
                                    {bottomList && <Box
                                        display='flex'
                                        flexDirection={isMobile ? 'column' : 'row'}
                                        justifyContent={!bottomListItemsAlign && 'center'}
                                        alignItems={isMobile ? 'center' : 'stretch'}
                                        gap={24}
                                        mt={!bottomListItemsAlign && 10}
                                        ml={bottomListItemsAlign && 8}
                                    >
                                        {bottomList?.map((item, index) =>
                                            <Box
                                                display='flex'
                                                key={index}
                                                justifyContent='center'
                                                alignItems={!isMobile ? !bottomListItemsAlign ? 'flex-start' : 'center' : 'center'}
                                                gap={!isMobile ? 16 : 12}
                                                mt={(!bottomListItemsAlign || (bottomListItemsAlign && isMobile)) && 10}
                                                onClick={() => redirectToAction(item.titleAction)}
                                                className={`
                                ${!bottomListItemsAlign && classes['rb-bottomListItems-root']}
                                    ${item.titleAction && classes['rb-bottomListItems-link']}
                                    ${!bottomListItemsAlign && (backgroundColor ? classes['rb-bottomListItems-regular'] : classes['rb-bottomListItems-light'])}
                                `}
                                            >
                                                <Box
                                                    display='flex'
                                                    flexDirection='column'
                                                    className={classes['rb-bottomListItems-icon']}
                                                >
                                                    {item.icon ? <img src={item.icon} width={parseInt(item.iconWidth) || 20} height={parseInt(item.iconHeight) || 20} alt='list icon' /> : <CheckIconCircled width={20} height={20} />}
                                                </Box>
                                                <Box display='flex' flexDirection='column' gap={4}>
                                                    {item.title && <Typography variant='textMd' weight='bold' color={theme.palette.primary.navy} className={item.titleAction && `${classes['rb-bottomListItems-titleAction']}`} align='left'>
                                                        <Markdown children={item.title} />
                                                    </Typography>}
                                                    {item.text && <Typography paragraph={true} variant='textSm' color={theme.palette.primary.navy} align='left'>
                                                        <Markdown children={item.text} />
                                                    </Typography>}
                                                </Box>
                                            </Box>)}
                                    </Box>}
                                </Box>
                            }
                            {secondaryButton}
                        </Box>}
                        {userReviews &&
                            <Box
                                mt={10}
                                display='flex'
                                alignItems='center'
                                gap={16}
                                justifyContent={isMobile ? 'center' : 'flex-start'}>
                                <NextImage
                                    src={Avatars}
                                    alt='userReview'
                                    width={152}
                                    height={40}
                                />
                                <Box display='flex' flexDirection='column' gap={2} className={classes['rb-reviews-box']}>
                                    <Box display='flex' alignItems='center' gap={8}><Typography variant='textMd' weight='semibold' color={backgroundColor ? '#FFF' : theme.palette.primary.navy}>4.6</Typography><Box display='flex' alignItems='center'>{[...new Array(5)].map((e, i) => <NextImage src={StarIcon} alt='stars' width={20} height={20} />)}</Box></Box>
                                    <Typography variant='textSm' weight='medium' color={backgroundColor ? '#FFF' : theme.palette.input.body}>Trusted by thousands</Typography>
                                </Box>
                            </Box>}
                        {customImage && !isMobile &&
                            <Box mt={10} display='flex' justifyContent={isMobile ? 'center' : 'flex-start'}>
                                <img
                                    src={customImage}
                                    alt={getImageAlt(customImage) || 'custom image'}
                                />
                            </Box>}
                        {list && <Spacing variant='betweenSections' />}
                        <Box display='flex' flexDirection={'column'} gap={16}>
                            {list && list.map((item, index) => {
                                return (
                                    <Box key={index} display='flex' flexDirection='row' gap={12}>
                                        <CheckIconCircled />
                                        <Typography
                                            className={classes['rb-list-item']}
                                            variant={'textMd'}
                                            weight={'regular'}
                                            color={theme.palette.input.body}
                                            align='left'
                                        >
                                            {item as React.ReactNode}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>

                    </Box>
                    {(imageUrl || contentType) && (
                        <>
                            {/* <div style={{ width: '100%', position: 'relative' }}>
                                <NextImage
                                    src={imageUrl}
                                    alt={getImageAlt(imageUrl) || 'imageAlt'}
                                    className={classes['rb-image']}
                                    style={loaded ? {} : { display: 'none' }}
                                    layout='fill'
                                    loader={({ src, width, quality }) => `${src}?w=${width}&q=${quality}`}
                                    loading='lazy'
                                    onLoad={() => {
                                        setLoaded(true)
                                    }}
                                />
                            </div> */}
                            {imageUrl && <Image
                                url={imageUrl}
                                alt={getImageAlt(imageUrl) || 'imageAlt'}
                                width={imageWidth}
                                height={imageHeight}
                                priority={true}
                            />}
                            {contentType && getComponents()}
                        </>
                    )}
                </Box>

                {socialProof && isMobile && <SocialProof />}
                {customImage && isMobile && <Box mt={10} display='flex' justifyContent={isMobile ? 'center' : 'flex-start'}>
                    <img
                        src={customImage}
                        alt={getImageAlt(customImage) || 'custom image'}
                    />
                </Box>}

                {bottomList && !bottomListItemsAlign && <Box
                    display='flex'
                    flexDirection={isMobile ? 'column' : 'row'}
                    justifyContent={!bottomListItemsAlign && 'center'}
                    alignItems={isMobile ? 'center' : 'stretch'}
                    gap={24}
                    mt={!bottomListItemsAlign && 12}
                    ml={bottomListItemsAlign && 8}
                >
                    {!isMobile && <Spacing variant='fromHeaderToContentInSection' />}
                    {bottomList?.map((item, index) =>
                        <Box
                            display='flex'
                            key={index}
                            justifyContent='center'
                            alignItems={!isMobile ? !bottomListItemsAlign ? 'flex-start' : 'center' : 'center'}
                            gap={!isMobile ? 16 : 12}
                            onClick={() => redirectToAction(item.titleAction)}
                            className={`
                            ${classes['rb-bottomListItems-root-box']}
                ${!bottomListItemsAlign && classes['rb-bottomListItems-root']}
                    ${item.titleAction && classes['rb-bottomListItems-link']}
                    ${!bottomListItemsAlign && (backgroundColor ? classes['rb-bottomListItems-regular'] : classes['rb-bottomListItems-light'])}
                `}
                        >
                            <div
                                className={classes['rb-bottomListItems-icon']}
                            >
                                {item.icon ? <img src={item.icon} width={parseInt(item.iconWidth) || 20} height={parseInt(item.iconHeight) || 20} alt='list icon' /> : <CheckIconCircled width={20} height={20} />}
                            </div>
                            <Box display='flex' flexDirection='column' gap={4} flexGrow={1}>
                                {item.title && <Typography variant='textMd' weight='bold' color={theme.palette.primary.navy} className={item.titleAction && `${classes['rb-bottomListItems-titleAction']}`} align='left'>
                                    <Markdown children={item.title} />
                                </Typography>}
                                {item.text && <Typography paragraph={true} variant='textSm' color={theme.palette.primary.navy} align='left'>
                                    <Markdown children={item.text} />
                                </Typography>}
                            </Box>
                        </Box>)}
                </Box>}
                {showHelpSearch &&
                    <Box className={classes['rb-help-search-container']}>
                        <HelpSearch
                        />
                    </Box>
                }
            </Box>
            {mainList && <Box
                display='flex'
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                flexWrap={'wrap'}
                mt={16}
            >
                <Spacing variant='fromHeaderToContentInSection' />
                {mainList?.map((item, index) =>
                    <Box
                        display='flex'
                        key={index}
                        justifyContent='center'
                        alignItems='flex-start'
                        flexDirection={isMobile ? 'column' : 'row'}
                        gap={16}
                        className={`
                    ${classes['rb-mainListItems-root']}
                `}
                        style={{ width: isMobile ? '100%' : `calc(100% / ${mainListGrid})` }}
                    >
                        <Box
                            display='flex'
                            flexDirection='column'
                            className={classes['rb-mainListItems-icon']}
                        >
                            {item.icon ? <img src={item.icon} width={isMobile ? 24 : 36} height={isMobile ? 24 : 36} alt='list icon' /> : <Placeholder color={theme.palette.primary.blue} width={isMobile ? 24 : 36} height={isMobile ? 24 : 36} />}
                        </Box>
                        <Box display='flex' flexDirection='column' gap={8}>
                            <Typography variant='textLg' weight='semibold' color={theme.palette.primary.navy} align='left'>
                                <Markdown children={item.title} />
                            </Typography>
                            <Typography paragraph={true} variant='textSm' align='left' className={classes['rb-mainListItems-text']}>
                                <Markdown children={item.text} />
                            </Typography>
                        </Box>
                    </Box>)}
            </Box>}
        </section>
    );
};

export default HeroSection;