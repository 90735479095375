import gql from "graphql-tag";

export const ARTICLE_BY_SLUG_QUERY = gql`  
  query getSingleArticle ($slug: String!) {
    blogArticles (
      filters: { 
        slug: { eq: $slug }
      }
    ) {
      data {
        id
        attributes {
          title
          content
          image {
            data {
              attributes {
                url
              }
            }
          }
          blog_sub_category {
            data {
              attributes {
                name
                slug
              }
            }
          }
          blog_category {
            data {
              attributes {
                title
                slug
                navigation_menu
                blog_articles (
                  filters: {
                    slug: { ne: $slug },
                    publishedAt: { notNull: true }
                  },
                  sort: "published_datetime:desc",
                  pagination: {
                    start: 0,
                    limit: 3
                  }
                ) {
                  data {
                    id
                    attributes {
                      title
                      excerpt
                      slug
                      published_datetime
                      alt_tag
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          published_datetime
          slug
          meta_title
          meta_description
          createdAt
          updatedAt
          alt_tag
          blog_tags {
            data {
              attributes {
                name
                slug
                blog_articles (
                  filters: {
                    slug: { ne: $slug },
                    publishedAt: { notNull: true }
                  },
                  sort: "published_datetime:desc",
                  pagination: {
                    start: 0,
                    limit: 3
                  }
                ) {
                  data {
                    id
                    attributes {
                      title
                      excerpt
                      slug
                      published_datetime
                      alt_tag
                      blog_category {
                        data {
                          attributes {
                            title
                            slug
                          }
                        }
                      }
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          image_reference {
            url
            name
          }
          promo_banner {
            banner_content
            cta
            cta_link
            banner_image {
              data {
                attributes {
                  url
                }
              }
            }
            primary_theme
            currency_code
          }
        }
      }
    }
  }
`;

export const LATEST_BLOG_ARTICLES = gql`
  query blogArticles($limit: Int!, $category: String, $slug: String) {
    blogArticles(
        sort: "published_datetime:desc",
        pagination: {
            start: 0,
            limit: $limit
        },
        filters: {
          blog_category: {
            slug: {
              eq: $category
            }
          },
          blog_tags: {
            slug: {
              eq: $slug
            }
          },
          publishedAt: {
            notNull: true
          }
        },
    ) {
        data {
            attributes {
                title
                excerpt
                slug
                image {
                    data {
                        attributes {
                            url
                        }
                    }
                }
                published_datetime
                alt_tag
            }
        }
    }
  }
`;