import React from 'react';
import classes from './DownloadAppSection.module.scss';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../../remitbee/theme/Theme';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import QRCode from '../../../../../../public/design-system/landing/DownloadAppSection/QRCode.svg';
import Image from '../../../../../remitbee/components/image/Image';
import AppBadgesSection from '../../../../../remitbee/sections/AppBadgesSection/AppBadgesSection';
import Container from '../../../../../remitbee/components/container/Container';

interface Props {
    title?: string;
    text?: string;
    showQRCode?: boolean;
    imageUrl?: string;
    isMobileServer?: boolean;
}

const DownloadAppSection: React.FC<Props> = ({
    title,
    text,
    showQRCode = true,
    imageUrl,
    isMobileServer
}) => {
    const isNotTablet = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
    const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);
    const isMobile = isMobileServer || useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

    return (
            <Box className={classes['rb-root']} flexDirection={isNotMobile ? 'row' : 'column'}>
                <Box
                    display='flex'
                    alignItems='center'
                    flexDirection={isMobile ? 'column' : 'row'}
                    gap={isMobile ? 48 : 64}
                    className={classes['rb-content']}
                >
                    <Box display='flex' flexDirection='column' className={classes['rb-root-box']} flexGrow={1}>
                        <Box display='flex' flexDirection='column' gap={16} className={classes['rb-root-box-text']}>
                            <Typography variant='displayLg' weight='bold' color={theme.palette.background.white} >
                                {title ? title : "Send on the go from any device"}
                            </Typography>
                            <Typography paragraph={true} variant='textMd' color={theme.palette.background.white}>
                                {text ? text : "Created for our customers on the go, the Remitbee app has all the functionality you expect from the website compacted into your iPhone or Android device!"}
                            </Typography>
                        </Box>

                        <Box
                            display={isNotMobile && 'flex'}
                            flexDirection={isNotMobile ? 'row' : 'column'}
                            justifyContent={isNotMobile && 'center'}
                        >
                            <div className={classes['rb-bottom-items-box']}>
                                <AppBadgesSection />
                            </div>
                            {showQRCode && isNotMobile && isNotTablet && <div className={classes['rb-qr']}>
                                <Box display='flex' justifyContent='center'>
                                    <Typography variant='textXs' color={theme.palette.background.white}>
                                        |
                                    </Typography>
                                </Box>
                                <Box display='flex' justifyContent='center'>
                                    <Typography variant='textXs' color={theme.palette.background.white}>
                                        or
                                    </Typography>
                                </Box>
                                <Box display='flex' justifyContent='center'>
                                    <Typography variant='textXs' color={theme.palette.background.white}>
                                        |
                                    </Typography>
                                </Box>
                            </div>}
                            {showQRCode && isNotMobile && isNotTablet && <div className={classes['rb-rq-code']}>
                                <div className={classes['rb-qr']} >
                                    <img src={QRCode} />
                                </div>
                                <Box display='flex' alignItems='center'>
                                    <Typography variant='textSm' color={theme.palette.background.white}>
                                        Scan QR with your phone to get the app
                                    </Typography>
                                </Box>
                            </div>}
                        </Box>
                    </Box>
                    <div className={classes['rb-devices-image-box']}>
                        <Image
                            url={imageUrl ? imageUrl : '/design-system/landing/DownloadAppSection/Devices.webp'}
                            alt='remitbee app download banner'
                            align='flex-end'
                            className={classes['rb-devices-image']}
                        />
                    </div>
                </Box>
            </Box>
    );
}

export default DownloadAppSection;
