import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';
import { interpolate, parseArrFromString } from '../../../shared/utility';
import Skeleton from '@mui/material/Skeleton';
import Markdown from 'markdown-to-jsx';
import { setRedirectSignupCookie } from '../../../shared/cookie-handler';
import theme from '../../../remitbee/theme/Theme';
import classes from './PromotionalHeroSection.module.scss';
import Container from '../../../remitbee/components/container/Container';
import Typography from '../../../remitbee/components/typography/Typography';
import Button from '../../../remitbee/components/button/Button';
import Box from '../../../remitbee/components/box/Box';
import { fetchCountriesMulticurrencyRest } from '../../../redux/system/actions';
import Flag from '../../../remitbee/components/flag/Flag';
import Image from '../../../remitbee/components/image/Image';
import ExchangeArrow from '../../../../public/new/rate-arrow.svg';
import { Variant } from '../../../remitbee/components/typography/Typography';

interface PromotionalHeroSectionProps {
    selectedCountry?: string;
    selectedCurrency?: string;
    topChip?: string;
    topChipIcon?: React.ReactNode;
    chipList?: any;
    title: string;
    subtitle?: string;
    actionText?: string;
    actionUrl?: string;
    desktopImage: string;
    imageAlt: string;
    isMobileServer?: boolean;
    imageWidth?: number;
    imageHeight?: number;
    titleVariant?: Variant;
    cardPadding?: string | null;
    rootPadding?: string | null;
}

const PromotionalHeroSection: React.FC<PromotionalHeroSectionProps> = ({
    selectedCountry,
    selectedCurrency,
    topChip,
    topChipIcon,
    chipList,
    title,
    subtitle,
    actionText,
    actionUrl,
    desktopImage,
    imageAlt,
    isMobileServer,
    imageWidth,
    imageHeight,
    titleVariant = 'display2Xl',
    cardPadding = null,
    rootPadding = null,
}) => {
    // const classes = useStyles();
    const router = useRouter();
    const isMobile = isMobileServer || useMediaQuery(`(max-width: ${theme?.breakpoints?.sm}px)`);
    const [listItems] = useState(typeof chipList === 'string' ? parseArrFromString(chipList) : chipList);
    const [specialRate, setSpecialRate] = useState({});
    const [finalSubtitle, setFinalSubtitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const fetchPromotionalRates = async () => {
            const rates = await fetchCountriesMulticurrencyRest();

            if (rates && rates.length) {
                const selectedCountryRateList = rates.filter(r => r && r.country_id === +selectedCountry);
                let selectedCountryRate = null;
                if (selectedCountryRateList && selectedCountryRateList.length) {
                    selectedCountryRate = selectedCountryRateList.find(r => r && r.currency_code === selectedCurrency) || selectedCountryRateList[0];
                }
                if (selectedCountryRate && Object.keys(selectedCountryRate).length) {
                    setSpecialRate({
                        ...selectedCountryRate,
                        special_rate: selectedCountryRate.special_rate ? (selectedCountryRate.special_rate?.includes('.') ? selectedCountryRate.special_rate : parseFloat(selectedCountryRate.special_rate).toFixed(2)) : selectedCountryRate.rate,
                        special_rate_adjustment: selectedCountryRate.special_rate_adjustment?.includes('.') ? selectedCountryRate.special_rate_adjustment : parseFloat(selectedCountryRate.special_rate_adjustment).toFixed(2),
                        special_rate_transfer_amount_limit: selectedCountryRate.special_rate_transfer_amount_limit?.includes('.') ? selectedCountryRate.special_rate_transfer_amount_limit : parseFloat(selectedCountryRate.special_rate_transfer_amount_limit).toFixed(2),
                    });
                    const final = interpolate(subtitle, selectedCountryRate);
                    setFinalSubtitle(final);
                }
                setLoading(false);
            } else setLoading(false);
        };

        if (selectedCountry) fetchPromotionalRates();

    }, [selectedCountry])

    const handleButtonClick = async () => {
        if (actionUrl && actionUrl.includes('signup')) {
            await setRedirectSignupCookie(router.asPath)
        }
        if (actionUrl) router.push(actionUrl);
    }

    const promotionalRate = (variant, small) => {
        const { special_rate, currency_code, iso2, rate }: { special_rate?: any, currency_code?: any, iso2?: string, rate?: any } = specialRate || {};

        return (
            <Box display={'flex'} alignItems='center' justifyContent='center' mt={small ? 0 : 3}>
                <Box display='flex' alignItems='center' gap={10}>
                    <Flag className={classes['rb-promotional-flag']} code="CA" />
                    <Box display='flex' alignItems='flex-end' gap={4}><Typography variant={'text2XL'} className={classes['rb-currency']} weight='semibold' align='center' style={variant ? { color: variant } : null}>
                        {`1.00`}
                    </Typography>
                        <Typography variant='textLg' weight='semibold' color='#FFF'>CAD</Typography></Box>
                </Box>

                <img width={small ? '35px' : '58px'} className={classes['rb-arrow']} src={ExchangeArrow} alt='Arrow' />

                <div>
                    {loading ? (
                        <Box display='flex' gap='8px' alignItems='center'>
                            <Skeleton variant="rectangular" height={15} className={small ? classes.flagIconSmall : classes.flagIcon} />
                            <Skeleton variant="rectangular" width={90} height={24} />
                        </Box>
                    ) : (
                        <Box display='flex' alignItems='center' gap={10}>
                            <Flag className={classes['rb-promotional-flag']} code={iso2} />
                            <Box display='flex' alignItems='flex-end' gap={4}>
                                <Typography variant={'text2XL'} className={classes['rb-currency']} weight='semibold' align='center' style={variant ? { color: variant } : null}>
                                    {special_rate ? special_rate : rate}
                                </Typography>
                                <Typography variant='textLg' weight='semibold' color='#FFF'>{currency_code}</Typography>
                            </Box>
                        </Box>
                    )}
                </div>
            </Box>
        )
    }


    return (
        <Container type='large' className={classes['rb-promotionalHero-container']} style={{ margin: rootPadding && rootPadding }}>
            <Grid container>
                <Grid item container className={classes['rb-whiteCard']} style={{ padding: cardPadding && cardPadding }}>
                    <Grid item xs={12} md={6} className={classes['rb-whiteCard-info']}>
                        {topChip && <Box display='flex' justifyContent={isMobile ? 'center' : 'left'}>
                            <div className={classes['rb-chip']}>
                                <img src={String(topChipIcon)} alt='Chip icon' />
                                <Typography variant='textSm' color={theme.palette.primary.blue} weight='semibold'>{topChip}</Typography>
                            </div>
                        </Box>
                        }

                        {title && <Typography variant={titleVariant} weight='bold' align={isMobile ? 'center' : 'left'} color={theme.palette.primary.navy}>{title}</Typography>}

                        {finalSubtitle !== '' && <Box pt={4}>
                            <Typography variant='textXl' align={isMobile ? 'center' : 'left'}>
                                <Markdown>{finalSubtitle}</Markdown>
                            </Typography>
                        </Box>}

                        {finalSubtitle === '' && (
                            // <Box className={classes.subtitleSkeleton}>
                            //     {Array.from(Array(4)).map((item, index) => (
                            //         <Skeleton variant='rectangular' height={27.9} key={index} />
                            //     ))}
                            // </Box>
                            <Box pt={4}>
                                {subtitle.includes('special_rate') ? <Typography variant='textXl' align={isMobile ? 'center' : 'left'}>
                                    <Markdown>{subtitle.split('1 CAD')[0]}</Markdown> <Skeleton width={200} height={30} />
                                </Typography> : <Typography variant='textXl' align={isMobile ? 'center' : 'left'}>
                                    <Markdown>{subtitle}</Markdown>
                                </Typography>}
                            </Box>
                        )}

                        <Box display='flex' justifyContent={isMobile ? 'center' : 'flex-start'} pt={isMobile ? 6 : 10}>
                            <div className={classes['rb-blueRate']}>
                                {specialRate && promotionalRate('white', true)}
                            </div>
                        </Box>

                        {actionText && actionUrl && <>
                            <Box pt={10} display='flex' justifyContent={isMobile ? 'center' : 'flex-start'}>
                                <Button id="action-button" variant='filledPrimary' onClick={handleButtonClick} className={classes['rb-promotional-button']}>{actionText}</Button>
                            </Box>
                        </>}
                    </Grid>
                    <Grid item xs={12} md={6} className={classes['rb-image-box']}>
                        {listItems && imageLoaded && <Box
                            className={classes['rb-blueChipList']}
                            display='flex'
                            flexWrap='wrap'
                            flexDirection='column'
                            alignItems='flex-end'>
                            {listItems.map((item, index) => (
                                <span className={classes['rb-blueTopChip']} key={index}>
                                    {item.icon && <img src={item.icon} alt='Chip icon' />}
                                    <Typography
                                        variant='textSm'
                                        weight='medium'
                                        color={theme.palette.background.white}>{item.text?.toUpperCase()}</Typography>
                                </span>
                            ))}
                        </Box>}
                        {desktopImage && (
                            <Box
                                display='flex'
                                alignItems='flex-end'
                                justifyContent='flex-end'
                            >
                                <Box
                                    className={classes['rb-promotional-image']}
                                    style={{ maxWidth: `${imageWidth}px` }}
                                >
                                    <Image
                                        url={desktopImage}
                                        alt={imageAlt || 'imageAlt'}
                                        priority
                                        onLoad={() => setImageLoaded(true)}
                                        width={imageWidth}
                                        height={imageHeight}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}


export default PromotionalHeroSection;
