import React, { useEffect, useRef, useState } from 'react';
import Container from '../../components/container/Container';
import Logo from '../../components/logo/Logo';
import classes from './NavigationBar.module.scss';
import Button from '../../components/button/Button';
import Sandwich from '../../icons/Sandwich';
import CloseIcon from '../../icons/CloseIcon';
import { useMediaQuery } from '@mui/material';
import theme from '../../theme/Theme';
import { useRouter } from 'next/router';
import NavigationBarMenu from './NavigationBarMenu';
import SendMoney from '../../icons/SendMoney';
import Exchange from '../../icons/Exchange';
import Bill from '../../icons/Bill';
import TopUp from '../../icons/TopUp';
import Language from '../../icons/Language';
import NavigationBarDrawer from './NavigationBarDrawer';
import { parseArrFromString } from '../../../shared/utility';
import { getClientHasAccountCookie } from '../../../shared/cookie-handler';
import { ACTIONS, Analytics } from '../../../settings/analytics';
import ESim from '../../icons/ESim';
import Box from '../../components/box/Box';
import Typography from '../../components/typography/Typography';

interface NavigationBarSectionProps {
    variant?: 'primary' | 'secondary';
    isMobileServer?: boolean;
    languages?: string;
    promotionalText?: string;
    promotionalLink?: string;
    promotionalUrl?: string;
}

const MENUS = [
    {
        label: "Services",
        id: "services",
        submenus: [
            {
                label: "Money transfers",
                id: "money-transfer",
                link: "/",
                description: "Send internationally to 100+ countries",
                icon: <SendMoney width={24} height={24} color={theme.palette.primary.blue} />
            },
            {
                label: "Currency exchange",
                id: "currency-exchange",
                link: "/currency-exchange",
                description: "Exchange CAD and USD seamlessly",
                icon: <Exchange width={24} height={24} color={theme.palette.primary.blue} />
            },
            {
                label: "Mobile topups",
                id: "mobile-topups",
                link: "/mobile-topup",
                description: "Buy phone plans and top-ups overseas",
                icon: <TopUp width={24} height={24} color={theme.palette.primary.blue} />
            },
            // {
            //     label: "Gift cards",
            //     id: "gift-cards",
            //     link: "/international-gift-cards",
            //     description: "Buy and send gift cards internationally",
            //     icon: <Gift width={24} height={24} color={theme.palette.primary.blue} />
            // },
            {
                label: "International bill payments",
                id: "gift-cards",
                link: "/international-bill-pay",
                description: "Pay international bills from Canada",
                icon: <Bill width={24} height={24} color={theme.palette.primary.blue} />
            },
            {
                label: "eSIM",
                id: "esim",
                link: "/esim",
                description: "Stay connected across the globe",
                icon: <ESim width={24} height={24} color={theme.palette.primary.blue} />
            }
        ]
    },
    {
        label: "Business",
        id: "business",
        link: "/business"
    },
    {
        label: "Refer & Earn",
        id: "referrrals",
        link: "/referrals"
    },
    {
        label: "Help",
        id: "help",
        link: "/help"
    }
]

const LANGUAGES = [
    {
        label: 'English',
        value: 'en',
    },
    {
        label: 'Português',
        value: 'pt',
    },
    {
        label: 'Français',
        value: 'fr',
    },
    {
        label: 'Spanish',
        value: 'es',
    },
    {
        label: 'தமிழ்',
        value: 'ta',
    },
    {
        label: 'සිංහල',
        value: 'si'
    },
    {
        label: 'ਪੰਜਾਬੀ',
        value: 'pa'
    },
    {
        label: 'Wikang Tagalog',
        value: 'tl'
    },
    {
        label: 'Hindi',
        value: 'hi'
    }
];

const NavigationBarSection: React.FC<NavigationBarSectionProps> = ({
    variant = 'secondary',
    isMobileServer,
    languages,
    promotionalText,
    promotionalLink,
    promotionalUrl
}) => {
    const router = useRouter();

    const getLanguages = () => {
        const availableLanguages = parseArrFromString(languages).map(lang => lang.language);

        if (availableLanguages[0]) {
            let renderLanguages = [
                {
                    label: 'English',
                    value: 'en'
                }
            ];

            availableLanguages.map(l => {
                renderLanguages.push(LANGUAGES.find(lang => lang.value === l))
            })

            return renderLanguages;
        }

        return null;
    }

    const isMobile = isMobileServer || useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
    const [displayLanguages, setDisplayLanguages] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [hasAccount, setHasAccount] = useState(false);
    const [additionalOffset, setAdditionalOffset] = useState(0);
    const isPrimaryVariant = variant === 'primary';
    const promoBarRef = useRef(null);

    useEffect(() => {
        const getCookie = async () => {
            if (await getClientHasAccountCookie()) {
                setHasAccount(true);
            }
        }
        getCookie();
    }, []);

    useEffect(() => {
        document.body.style.overflow = drawerOpen ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [drawerOpen]);

    useEffect(() => {
        if (drawerOpen) setDrawerOpen(false);
    }, [router?.asPath]);

    useEffect(() => {
        if (promoBarRef?.current) {
          const height = promoBarRef.current?.offsetHeight;
          if (height) setAdditionalOffset(height);
        }
      }, [promoBarRef]);

    useEffect(() => {
        setSelectedLanguage(router?.locale)
    }, [router?.locale]);

    useEffect(() => {
        setDisplayLanguages(getLanguages());
    }, [languages]);

    const handleOpenMenu = (path: string) => {
        router.push(path);
    }

    const onChangeLanguage = (language) => {
        localStorage.setItem('i18nextLng', language);
        Analytics.track(ACTIONS.LANGUAGE_CHANGED, { language: language });
        router.replace(router.asPath, router.asPath, { locale: language });
    }

    return (
        <>
            {promotionalText && (
                <Box
                    className={classes[variant === 'primary' ? 'rb-promotional-banner-primary' : 'rb-promotional-banner-secondary']}
                    componentRef={promoBarRef}
                >
                    <Container type="large">
                        <Box display='flex'
                            justifyContent='center'
                            pl={isMobile && 6}
                            pr={isMobile && 6}
                        >
                            <Typography
                                variant='textLg'
                                weight='semibold'
                                align='center'
                                color={variant === 'primary' ? theme.palette.primary.navy : theme.palette.background.white}>
                                {promotionalText}
                                {promotionalLink && promotionalUrl && <a
                                    href={promotionalUrl}
                                    target="_blank"
                                    className={variant === 'primary' ? classes['rb-navigationBar-promotional-link-primary'] : classes['rb-navigationBar-promotional-link']}
                                >
                                    {promotionalLink}
                                </a>}
                            </Typography>
                        </Box>
                    </Container>
                </Box>
            )}
            <Container type="large" className={promotionalText ? classes['rb-navigationBar-container-promotional'] : classes['rb-navigationBar-container']}>
                <div className={classes['rb-navigationBar-root']}>
                    <div className={classes['rb-navigationBar-logo']}>
                        {isMobile && (
                            <div onClick={() => setDrawerOpen(!drawerOpen)} className={classes['rb-navigationBar-mobile-icon']}>
                                {drawerOpen ?
                                    <CloseIcon width={24} height={24} color={isPrimaryVariant ? theme.palette.background.white : theme.palette.primary.blue} /> :
                                    <Sandwich width={24} height={24} color={isPrimaryVariant ? theme.palette.background.white : theme.palette.primary.blue} />}
                            </div>
                        )}
                        {/* Logo and Header had reversed logic for primary and secondary */}
                        <div className={classes['rb-navigationBar-logo-icon']} onClick={() => router.push('/')}>
                            <Logo isMobile={isMobile} variant={isPrimaryVariant ? 'secondary' : 'primary'} />
                        </div>
                    </div>

                    <div className={classes['rb-navigationBar-buttons']}>
                        {!isMobile && <div className={isPrimaryVariant ? classes['rb-navigationBar-button-links-primary'] : classes['rb-navigationBar-button-links']}>
                            {MENUS.map(menu =>
                                <nav key={menu.id}>
                                    <NavigationBarMenu
                                        id={menu.id}
                                        label={menu.label}
                                        onClick={menu.link ? () => handleOpenMenu(menu.link) : null}
                                        submenus={menu.submenus}
                                        isPrimaryVariant={isPrimaryVariant}
                                    />
                                </nav>
                            )}
                            {displayLanguages && <NavigationBarMenu
                                id='language-dropdown'
                                label={selectedLanguage?.toUpperCase()}
                                onChangeLanguage={(e) => onChangeLanguage(e)}
                                icon={<Language width={20} height={20} color={isPrimaryVariant ? theme.palette.background.white : theme.palette.primary.navy} />}
                                submenus={displayLanguages}
                                isPrimaryVariant={isPrimaryVariant}
                            />}
                        </div>}
                        {!(isMobile && !hasAccount) && <Button
                            className={classes['rb-navigationBar-button']}
                            id='log-in'
                            variant={isPrimaryVariant ? 'outlinedWhite' : 'outlined'}
                            onClick={() => router.push('/login')}>
                            {'Log in'}
                        </Button>}
                        {!(isMobile && hasAccount) && <Button
                            className={`${classes['rb-navigationBar-button']} ${isPrimaryVariant && !isMobile && classes['rb-navigationBar-primary-signup-button']}`}
                            id='sign-up'
                            variant={isPrimaryVariant ? (isMobile ? 'outlinedWhite' : 'white') : (isMobile ? 'outlined' : 'filled')}
                            onClick={() => router.push('/signup')}>
                            {'Sign up'}
                        </Button>}
                    </div>
                </div>
            </Container>
            {drawerOpen && (
                <NavigationBarDrawer
                    menus={MENUS}
                    handleOpenMenu={handleOpenMenu}
                    displayLanguages={displayLanguages}
                    isPrimaryVariant={isPrimaryVariant}
                    onChangeLanguage={(e) => onChangeLanguage(e)}
                    router={router}
                    additionalOffset={promotionalText && additionalOffset}
                />
            )}
        </>
    );
};

export default NavigationBarSection;